import React from "react";
import Slider from "react-slick";
import '../index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MenuCarousel() {
    const settings = {
        dots: false,
        draggable: false,
        infinite: true,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
        
  };
  return (
    <div className="menu__container-carousel">
    <div className="slider-container">
      <Slider {...settings}>
      <div className='menu--box-carousel'>
      <div className='menu--item'>Railway Lamb <span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£10.00</div>
        <div className='menu--item-description'>Spring lamb cooked with fresh coriander, garlic and green chilli with chef's special spice in a thick sauce.</div>
      </div>

      <div className='menu--box-carousel'>
      <div className='menu--item'>Chicken Tikka Masala <span className='red'>🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with almonds, coconut, nuts & sultanas in a mild sauce.</div>
        <br></br>
      </div>

      <div className='menu--box-carousel'>
      <div className='menu--item'>Indian Special Kebab</div>
        <div className='menu--item-price'>£7.50</div>
        <div className='menu--item-description'>Chicken tikka, doner meat, onion bhaji & chicken pakora fried onion, pepper.</div>
        <br></br>
      </div>

      <div className='menu--box-carousel'>
        <div className='menu--item'>Doner Kebab with Chips</div>
        <div className='menu--item-price'>Regular £5.00 • Large £6.00</div>
        <div className='menu--item-description'><br></br><br></br><br></br>
      </div>

      </div>

      <div className='menu--box-carousel'>
      <div className='menu--item'>Chicken Jalfrezi<span className='red'>🌶️🌶️🌶️</span></div>
        <div className='menu--item-price'>£9.00</div>
        <div className='menu--item-description'>Cooked with fresh green chillies, peppers & coriander with chef special sauce to give a rich flavour.</div>
      </div>

      </Slider>
    </div>
    </div>
  );
}

export default MenuCarousel;
