import React from 'react';
import '../index.css';
import MyMap from './MyMap';

const FindUs = () => {
  return (
    <section id="find-us">
      <div className="find-us__container">
        <div className="find-us__row">
          <div className="find-us__left-column">
            <div className="find-us__heading">
              <h6 className="find-us__title red">FIND US</h6>
            </div>
            <div className="opening-times__container">
              {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day, index) => (
                <div className="opening-times__day" key={index}>
                  <div className="opening-times__left-column">
                    <h6 className="opening-times__days">{day}</h6>
                  </div>
                  <div className="opening-times__right-column">
                    <h6 className="opening-times__times">
                      {day === 'Sunday' ? '5:00pm - 12:00am' : '5:30pm - 11:30pm'}
                    </h6>
                  </div>
                </div>
              ))}
            </div>
          </div >
          <div className="find-us__img-wrapper">
            <MyMap></MyMap>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindUs;


// AIzaSyDeuKTdpmIzgs8TGbWwF3tFk5IvrZc1gyw